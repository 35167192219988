import { startTransition, useState } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { RemixBrowser } from '@remix-run/react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import i18n from './i18n'
import Backend from 'i18next-http-backend'
import { reportLoadingProgress } from 'services/loadingProgress'
import createEmotionCache from '@emotion/cache'
import { sentryService } from 'services/SentryService'
import { logger } from 'services/logger'

import 'core-js-pure/stable/aggregate-error' // Required for supporting the 'cause' property in error constructor
import 'polyfills/canvas-to-blob' // For old browsers & all Edge versions
import 'polyfills/flat' // For old browsers & all Edge versions
import 'blueimp-canvas-to-blob'
import { installServiceWorkerIfNeeded } from '../serviceWorkerInstallation'
import MuiProvider from 'components/MuiProvider/MuiProvider'
import { experimentManager } from '../services/ExperimentManager/ExperimentManager'
import { forceVariantsFromURL } from '../services/ExperimentManager/Experiment.utils'

installServiceWorkerIfNeeded()
reportLoadingProgress()
experimentManager.removeForcedVariantsIfNeeded()

forceVariantsFromURL(
  new URLSearchParams(window.location.search),
  experimentManager
)

// Creating emotion cache from MUI styles
function MuiClientCacheProvider({ children }: { children: React.ReactNode }) {
  const [cache] = useState(() => createEmotionCache({ key: 'css' }))

  return <MuiProvider value={cache}>{children}</MuiProvider>
}

async function hydrate() {
  const rootLoaderData = window.__remixContext?.state?.loaderData?.root
  const username = rootLoaderData?.user
  logger.init(rootLoaderData?.keys, username)
  if (rootLoaderData?.keys) sentryService.initSentry(rootLoaderData.keys)

  await i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(Backend) // Setup your backend
    .init({
      ...i18n, // spread the configuration
      // This function detects the namespaces your routes rendered while SSR use
      lng: rootLoaderData?.language || 'en',
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ['htmlTag'],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
    })

  startTransition(() => {
    hydrateRoot(
      document.getElementById('root')!,
      <I18nextProvider i18n={i18next}>
        <MuiClientCacheProvider>
          <RemixBrowser />
        </MuiClientCacheProvider>
      </I18nextProvider>
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
